<template>
  <v-sheet>
    <div style="min-height: 500px">
      <v-row class="my-2 ml-1 mt-4">
        <v-col>
          <div
            style="
              width: 513px;
              background: transparent;
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              height: 40px;
              box-shadow: unset;
              margin-top: 10px;
            "
          >
            <p
              style="
                font-size: 18px;
                margin-top: 5px;
                margin-left: 30px;
                color: #707070;
              "
            >
              {{ quiz_name }}
            </p>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-col>
          <v-btn
            outlined
            depressed
            :disabled="certificateItems?.length == 1"
            style="
              font-size: 16px;
              border: 1px solid #c0c0c0;
              color: #ffffff !important;
              background-color: #a6cc39;
            "
            class="mt-5 text-capitalize text--white rounded-lg"
            @click="OpenAddCertDialog()"
          >
            <v-icon class="mr-2" style="color: white !important"
              >mdi-plus-box-outline</v-icon
            >
            {{ $t("addcertificate") }}
          </v-btn>
        </v-col>
      </v-row>
      <div class="my-3 ml-4 mt-1">
        <v-data-table
          :loading="loading"
          :loading-text="$t('loadingpleasewait')"
          :headers="certificate_header"
          :items="certificateItems"
          hide-default-footer
          class="editquiz-table"
        >
          <template v-slot:header.emailAutoSend="{ header }">
            <div class="d-flex align-center">
              {{ header.text }}
              <div class="tooltip">
                <v-icon
                  style="color: #363636; cursor: pointer"
                  class="mt-2 ml-2 mb-2"
                >
                  mdi-chat-alert-outline
                </v-icon>
                <span class="tooltipText">
                  {{ $t("autoemailsend") }}
                </span>
              </div>
            </div>
          </template>

          <template v-slot:item.templateImage="{ item }">
            <v-img
              :src="item.templateImage"
              aspect-ratio="1"
              contain
              class="certTableImg"
            >
            </v-img>
          </template>
          <template v-slot:item.emailAutoSend="{ item }">
            <div class="onoffswitch mt-3 ml-5">
              <input
                type="checkbox"
                name="onoffswitch"
                class="onoffswitch-checkbox"
                id="randomOnOffSwitch1"
                v-model="item.emailAutoSend"
                @change="UpdateCertAutoMailSendStatus(item)"
              />
              <label class="onoffswitch-label" for="randomOnOffSwitch1">
                <div class="onoffswitch-inner"></div>
                <div class="onoffswitch-switch"></div>
              </label>
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <div class="onoffswitch mt-3 ml-5">
              <input
                type="checkbox"
                name="onoffswitch"
                class="onoffswitch-checkbox"
                id="randomOnOffSwitch2"
                v-model="item.status"
                @change="UpdateCertStatus(item)"
              />
              <label class="onoffswitch-label" for="randomOnOffSwitch2">
                <div class="onoffswitch-inner"></div>
                <div class="onoffswitch-switch"></div>
              </label>
            </div>
          </template>
          <template v-slot:header.autosendemail>
            <div>
              <v-icon class="mr-2">mdi-email</v-icon>
              {{ $t("autosendemail") }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              text
              color="#4FB14E"
              class="text-capitalize"
              style="font-size: 14px"
              @click="Edit_Certificate(item)"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
              {{ $t("edit") }}
            </v-btn>
            <v-btn
              text
              color="#FF6060"
              style="font-size: 14px"
              class="text-capitalize"
              @click="Show_DeleteCertDialog(item)"
            >
              <v-icon>mdi-delete-outline</v-icon>
              {{ $t("delete") }}
            </v-btn>
          </template>
        </v-data-table>
      </div>
      <v-divider class="ml-4" style="border-color: #7070707f; margin-top: 30px"></v-divider>
      <!-- List of Graduate Data -->
      <v-row class="ma-1">
        <v-col>
          <label
            for="listofgraduates"
            style="
              font-size: 24px;
              color: #47484b;
              margin-top: 5px;
              margin-right: 20px;
            "
            >{{ $t("listofgraduates") }}</label
          >
        </v-col>
      </v-row>
      <v-row class="ma-1 mt-4">
        <div
          style="
            width: 513px;
            background: transparent;
            border: 1px solid #c0c0c0;
            border-radius: 8px;
            height: 40px;
            box-shadow: unset;
            margin-top: 10px;
            margin-left: 10px;
          "
        >
          <p
            style="
              font-size: 18px;
              margin-top: 5px;
              margin-left: 30px;
              color: #707070;
            "
          >
            {{ fullminimumscore }}
          </p>
        </div>
      </v-row>
      <v-row class="ml-1 mb-1">
        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
          <label
            for="status"
            style="
              font-size: 16px;
              color: #424242;
              font-weight: bold;
              margin-top: 5px;
            "
            >{{ $t("status") + $t("pass-fail") }}</label
          >
          <v-select
            v-model="scoreStatusList"
            :items="filterscoreItems"
            hide-details
            item-text="name"
            item-value="name"
            hide-no-data
            dense
            outlined
            solo
            clearable
            multiple
            :placeholder="'--' + $t('pleaseselect') + '--'"
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="searchscoredata"
                    :placeholder="$t('search')"
                    autocomplete="off"
                    hide-details
                    class="searchinlist"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-list-item ripple @click="getscoreSelectAll">
                <v-list-item-action>
                  <v-icon
                    v-if="scoreStatusList.length == filterscoreItems.length"
                    color="#a6cc39"
                  >
                    mdi-checkbox-marked</v-icon
                  >
                  <v-icon
                    v-else
                    :color="scoreStatusList.length > 0 ? '#a6cc39' : ''"
                    >{{ icon }}</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption"
                >(+{{ scoreStatusList.length - 1 }} others)</span
              >
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
          <label
            for="group"
            style="
              font-size: 16px;
              color: #424242;
              font-weight: bold;
              margin-top: 5px;
            "
            >{{ $t("group") }}</label
          >
          <v-select
            v-model="dept"
            :items="groupOptions"
            hide-details
            item-text="group"
            item-value="group"
            hide-no-data
            solodense
            dense
            outlined
            solo
            clearable
            multiple
            :placeholder="'--' + $t('pleaseselect') + '--'"
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="searchgroup"
                    :placeholder="$t('search')"
                    autocomplete="off"
                    hide-details
                    class="searchinlist"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-list-item ripple @click="getgroupSelectAll">
                <v-list-item-action>
                  <v-icon :color="dept.length > 0 ? '#a6cc39' : ''">{{
                    icon
                  }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption"
                >(+{{ dept.length - 1 }} others)</span
              >
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
          <label
            for="fullnamesm"
            style="
              font-size: 16px;
              color: #424242;
              font-weight: bold;
              margin-top: 5px;
            "
            >{{ $t("fullnamesm") }}</label
          >
          <v-select
            v-model="QuizUserIdSelect"
            :items="FullNameItems"
            item-text="fullName"
            item-value="userId"
            hide-no-data
            :placeholder="'--' + $t('pleaseselect') + '--'"
            class="quiz-name"
            hide-details
            outlined
            solo
            dense
            clearable
            multiple
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="searchuser"
                    :placeholder="$t('search')"
                    @input="searchInLists(), (searchtype = 'user')"
                    autocomplete="off"
                    hide-details
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-list-item ripple @click="getSelectAll">
                <v-list-item-action>
                  <v-icon
                    :color="QuizUserIdSelect.length > 0 ? '#a6cc39' : ''"
                    >{{ icon }}</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.fullName }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption"
                >(+{{ QuizUserIdSelect.length - 1 }} others)</span
              >
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="2" xl="2" class="d-flex align-end">
          <v-btn
            text
            class="text-capitalize mr-3"
            min-height="40"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
              font-size: 16px;
            "
            >{{ $t("clearall") }}</v-btn
          >
          <v-btn
            depressed
            class="text-capitalize btnStyle1 btn_hover_effect"
            color="#A6CC39"
            min-width="105"
            min-height="40"
            >{{ $t("search") }}</v-btn
          >
        </v-col>
        <!-- <v-col cols="12" sm="6" md="3" xl="1" class="d-flex align-end">
          <v-btn
            width="135"
            depressed
            class="text-capitalize btnStyle1 btn_hover_effect"
            color="#A6CC39"
            :disabled="sendEmailList.length == 0"
            @click="sendMailByList()"
            >{{ $t("sendemail") }}</v-btn
          >
        </v-col> -->
      </v-row>
      <div class="ma-3 mt-1">
        <v-data-table
          :loading="loading"
          :loading-text="$t('loadingpleasewait')"
          :headers="listOfgraduate_header"
          show-select
          :items="listOfgraduatesItems"
          hide-default-footer
          class="editquiz-table"
          v-model="sendEmailList"
          @page-count="gratuateTablePageCount = $event"
          :page.sync="gratuateTablePage"
          :items-per-page="graduateItemsPerPage"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              text
              color="#4FB14E"
              class="text-capitalize"
              style="font-size: 16px"
              @click="Edit_ListOfGraduate(item)"
            >
              <v-icon style="font-size: 16px" class="mr-2"
                >mdi-square-edit-outline</v-icon
              >
              {{ $t("edit") }}
            </v-btn>
            <v-btn
              text
              color="#424242"
              style="font-size: 16px"
              class="text-capitalize"
              @click="Preview_ListOfGraduate(item)"
            >
              <v-icon style="font-size: 16px" class="mr-2"
                >mdi-eye-outline</v-icon
              >
              {{ $t("preview") }}
            </v-btn>
          </template>
          <template v-slot:item.send="{ item }">
            <v-btn
              color="#6fdc42"
              class="text-capitalize"
              style="font-size: 16px; color: #ffffff; height: 30px"
              @click="SendCertConfirmDialog(item)"
            >
              <v-icon style="font-size: 16px; transform: rotate(320deg)"
                >mdi-send</v-icon
              >
              {{ $t("send") }}
            </v-btn>
          </template>
          <template v-slot:item.scoreStatus="{ item }">
            <v-sheet
              v-if="passingScore != 0"
              height="20px"
              width="65px"
              line-height="30px"
              :class="
                item.scoreStatus === 'graduate-pass'
                  ? 'status_list'
                  : 'status_list1'
              "
            >
              {{ $t(item.scoreStatus) }}
            </v-sheet>
          </template>
          <template v-slot:item.history="{ item }">
            <v-btn
              text
              color="#A6CC39"
              class="text-capitalize"
              @click="ClickHistoryIcon(item)"
            >
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-row justify="space-between" class="mx-3 my-4">
          <v-row>
            <v-col cols="auto" class="mr-auto">
              <v-pagination
                circle
                v-model="gratuateTablePage"
                class="pagination"
                :length="gratuateTablePageCount"
              ></v-pagination>
            </v-col>

            <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
              <p class="mt-3 pr-4">{{ $t("Show") }}</p>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    width="70"
                    v-bind="attrs"
                    v-on="on"
                    style="border: 1px solid #c0c0c0; opacity: 1"
                    class="text-capitalize mt-1"
                    outlined
                  >
                    {{ graduateItemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in paginationCount"
                    :key="index"
                    @click="graduateItemsPerPage = item.title"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-row>
      </div>
    </div>
    <PrepareCertificate
      :show_dialog="showCertificateDialog"
      @close_dialog="(showCertificateDialog = false), GetCertificateByQuizID()"
      :q_Item="requiredDataForCert"
      :dialog_title="certificateTitle"
    />
  </v-sheet>
</template>

<script>
import axios from "axios";
import PrepareCertificate from "../../Certificate/PrepareCertificate.vue";

export default {
  components: {
    PrepareCertificate,
  },
  props: {
    quizName: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    totalScoreInSetting: 0,
    passingScore: 0,
    quizId: localStorage.getItem("QuizId"),
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    graduateItemsPerPage: 10,
    gratuateTablePageCount: 0,
    gratuateTablePage: 1,
    listOfgraduatesItems: [],
    // select status and stuff
    searchuser: null,
    searchgroup: null,
    sendEmailList: [],
    QuizUserIdSelect: [],
    dept: [],
    FullNameItems: [],
    scoredataitems: [
      {
        name: "Pass",
      },
      {
        name: "Fail",
      },
    ],
    scoreStatusList: [],
    //status and staff.......
    createdBy: null,
    memberId: null,
    certificateTitle: "addcertificate",
    requiredDataForCert: {},
    showCertificateDialog: false,
    groupdata: [],
    searchscoredata: "",
    loading: false,
    certificateItems: [],
  }),

  computed: {
    listOfgraduate_header() {
      return [
        {
          text: this.$t("no"),
          value: "no",
          class: "tableColor",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("fullnamesm"),
          align: "left",
          value: "fullName",
          class: "tableColor",
          width: "120",
          sortable: true,
        },
        {
          text: this.$t("group"),
          align: "left",
          value: "group",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("email"),
          align: "left",
          value: "email",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("highest"),
          align: "left",
          value: "highestScore",
          class: "tableColor",
          width: "120",
          sortable: true,
        },
        {
          text: this.$t("lastsentdate"),
          align: "left",
          value: "lastSentDate",
          class: "tableColor",
          width: "150",
          sortable: true,
        },
        {
          text: this.$t("times"),
          align: "left",
          value: "sentCount",
          class: "tableColor",
          width: "100",
          sortable: true,
        },
        {
          text: this.$t("passedthecriteria"),
          align: "left",
          value: "scoreStatus",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("Manage"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
        {
          text: this.$t("sendemail"),
          align: "left",
          value: "send",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("history"),
          align: "left",
          value: "history",
          class: "tableColor",
          sortable: false,
        },
      ];
    },
    // select status and stuff
    icon() {
      if (this.selectAll || this.selectGroupAll || this.selectdataAll)
        return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    selectGroupAll() {
      return this.dept.length === this.groupdata.length;
    },
    groupOptions() {
      const query = (this.searchgroup || "").toLowerCase();
      const uniqueGroups = [
        ...new Set(
          this.groupdata.map((obj) => (obj.group !== null ? obj.group : "null"))
        ),
      ];
      return uniqueGroups.filter((group) =>
        group.toLowerCase().includes(query)
      );
    },
    filterscoreItems() {
      const searchTerm = this.searchscoredata.toLowerCase();
      return this.scoredataitems.filter((item) =>
        item.name.toLowerCase().includes(searchTerm)
      );
    },
    // selecting status and stuff

    fullminimumscore() {
      return this.$t("full-minimum-score")
        .replace("{0}", this.totalScoreInSetting || 0)
        .replace("{1}", this.passingScore || 0);
    },
    quiz_name() {
      return this.quizName;
    },

    certificate_header() {
      return [
        {
          text: this.$t("certificatebackground"),
          value: "templateImage",
          class: "tableColor",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("title"),
          align: "center",
          value: "title",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("course"),
          align: "center",
          value: "courseName",
          class: "tableColor",
          sortable: false,
        },
        // {
        //   text: this.$t("emailsentformat"),
        //   align: "left",
        //   value: "emailAutoSend",
        //   class: "tableColor",
        //   sortable: false,
        // },
        {
          text: this.$t("sent"),
          align: "center",
          value: "sentUserCount",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("notsentyet"),
          align: "center",
          value: "notSentUserCount",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("autosendemail"),
          align: "left",
          value: "emailAutoSend",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("status"),
          align: "left",
          value: "status",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("Manage"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
  },

  mounted() {    
    const auth = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    if (auth) {
      this.createdBy = auth.fullName;
      this.memberId = auth.memberId;
    }
    this.GetCertificateByQuizID();
    this.GetQuizSetting();
    this.GetQuizInsights();
  },

  methods: {

    async GetQuizInsights() {
      let that = this;
      if(that.quizId === null) return;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.quizId,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizInsights`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.totalScoreInSetting = response.data.data.score;
            
          }
        })
        .catch(function (err) {
          throw err;
        });
    },

    async GetQuizSetting() {
      let that = this;
      if(that.quizId === null) return;
  
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.quizId,
        createOrUpdateBy: that.memberId,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizSetting`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.passingScore =
              response.data.data.passingScore != null
                ? response.data.data.passingScore
                : 0;
           
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },
    
    async UpdateCertStatus(item) {
      let that = this;
      let request = {
        certificateID: item.certificateID,
        status: item.status,
      };
      await axios
        .post(`${that.web_url}Certificate/UpdateCertStatus`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            that.GetCertificateByQuizID();
          }
        });
    },
    async UpdateCertAutoMailSendStatus(item) {
      let that = this;
      let request = {
        certificateID: item.certificateID,
        emailAutoSend: item.emailAutoSend,
      };

      await axios
        .post(
          `${that.web_url}Certificate/UpdateCertMailAutoSendStatus`,
          request
        )
        .then(function (res) {
          if (res.data.status == 0) {
            that.GetCertificateByQuizID();
          }
        });
    },
    //select status and stuff...
    getSelectAll() {
      if (this.QuizUserIdSelect.length == 0) {
        this.QuizUserIdSelect = this.FullNameItems.map(({ userId }) => userId);
      } else {
        this.QuizUserIdSelect = [];
      }
    },
    getgroupSelectAll() {
      if (this.dept.length == 0) {
        this.dept = this.groupdata.map(({ group }) => group);
      } else {
        this.dept = [];
      }
    },
    searchInLists() {
      if (this.searchtype == "user") {
        if (!this.searchuser) {
          this.FullNameItems = this.userCopy;
        }

        this.FullNameItems =
          this.searchuser != null
            ? this.FullNameItems.filter((x) => {
                return (
                  x.fullName
                    .toLowerCase()
                    .indexOf(this.searchuser.toLowerCase()) > -1
                );
              })
            : this.userCopy;
      } else if (this.searchtype == "group") {
        if (!this.searchgroup) {
          this.groupOptions = this.groupdata;
        }

        this.groupOptions =
          this.searchgroup != null
            ? this.groupOptions.filter((x) => {
                return (
                  x.group
                    .toLowerCase()
                    .indexOf(this.searchgroup.toLowerCase()) > -1
                );
              })
            : this.groupdata;
      }
    },
    getscoreSelectAll() {
      if (this.scoreStatusList.length == 0) {
        this.scoreStatusList = this.scoredataitems;
      } else {
        this.scoreStatusList = [];
      }
    },
    //end of search status and staff..............
    Edit_Certificate(item) {
      this.certificateTitle = "editcertificate";
      this.showCertificateDialog = true;
      const temp = {
        type: 2,
        memberId: this.memberId,
        courseId: 0, // this.courseId,
        create_by: this.createdBy,
        course_name: null,
        // this.courseOfQuiz[0]?.courseName
        //   ? this.courseOfQuiz[0]?.courseName
        //   : null,
        certificate_Item: item,
      };
      this.requiredDataForCert = temp;
    },

    OpenAddCertDialog() {
      let that = this;
      //that.addCertDialog = true;
      that.certificateTitle = "addcertificate";
      that.showCertificateDialog = true;
      const temp = {
        type: 1,
        memberId: that.memberId,
        courseId: 0, // that.courseId,
        create_by: that.createdBy,
        course_name: null,
        certificate_Item: null,
      };
      that.requiredDataForCert = temp;
    },

    async GetCertificateByQuizID() {      
      let that = this;
      let quizId = that.quizId ?? null;
      if (that.quizId == null) {        
        return;
      }
      await axios
        .get(
          `${that.web_url}Certificate/GetCertificateByQuizID?QuizID=` + quizId
        )
        .then(function (response) {
          if (response.data.status === 0 && response.data.data.length != 0) {
            that.certificateItems = response.data.data.map((v) => ({
              ...v,
              emailAutoSend: v.emailAutoSend,
            }));
          } else {
            that.certificateItems = [];
          }
        });
    },
  },
};
</script>

<style scoped>
.onoffswitch {
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 28px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #6fdc42;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.onoffswitch-switch {
  width: 26px;
  height: 26px;
  margin: 1px;
  background: #ffffff;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
::v-deep .v-dialog {
  border-radius: 10px !important;
}
::v-deep .theme--light.v-btn.v-btn--disabled {
  background-color: #cccccc !important;
  color: #ffffff !important;
}
::v-deep .btnStyle1 {
  font-size: 16px;
  height: 40px;
  color: #ffffff;
  border-radius: 8px;
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
} /**.child_table */
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
.certTableImg {
  margin: 10px 0px;
  border-radius: 5px;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 14px !important;
  color: #424242 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 14px !important;
  color: #424242 !important;
  opacity: 1;
  text-align: center;
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}
::v-deep .editquiz-table.v-data-table {
  max-width: 100%;
}
::v-deep .editquiz-table tr:nth-child(even) {
  background: #e0e0e0;
  margin-top: 0.5rem;
}
::v-deep .editquiz-table .v-input--selection-controls__input .v-icon {
  color: #a6cc39 !important;
  border-radius: 4px;
  opacity: 1;
}
.tooltip .tooltipText {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  transition: opacity 0.3s;
}

.tooltip .tooltipText::after {
  content: " ";
  position: absolute;
  top: 10%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tooltip:hover .tooltipText {
  visibility: visible;
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  /* width: 80%; */
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
</style>
