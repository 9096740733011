<template>
  <v-sheet>
    <v-row class="my-1 ml-2 mt-4">
      <div
        style="
          width: 513px;
          background: transparent;
          border: 1px solid #c0c0c0;
          border-radius: 8px;
          height: 40px;
          box-shadow: unset;
          margin-top: 10px;
          margin-left: 10px;
        "
      >
        <p
          style="
            font-size: 18px;
            margin-top: 5px;
            margin-left: 30px;
            color: #707070;
          "
        >
          {{ quiz_name }}
        </p>
      </div>
    </v-row>
    <div class="my-3 ml-4 mt-5">
      <v-data-table
        :loading="loading"
        :loading-text="$t('loadingpleasewait')"
        :headers="CourseTableHeaders"
        :items="courseItems"
        hide-default-footer
        class="editquiz-table"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-xs-center">
              <p>{{ "1" }}</p>
            </td>
            <td class="text-xs-center">
              <p>{{ item.courseName }}</p>
            </td>
            <td class="text-xs-start">
              <p>{{ item.startDate }}</p>
            </td>
            <td class="text-xs-start">
              <p>{{ item.endDate }}</p>
            </td>

            <td class="text-xs-start">
              <p>{{ item.totalUser }}</p>
            </td>
            <td class="text-xs-center">
              <p style="width: 90px">{{ item.quizFinished }}</p>
            </td>
            <td class="text-xs-start">
              <p style="width: 100px">{{ item.notStarted }}</p>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider style="border-color: #7070707f; margin-top: 30px"></v-divider>
    </div>
    <p
      style="
        font-size: 18px;
        margin-top: 10px;
        margin-left: 22px;
        color: #424242;
      "
    >
      {{
        $t("insights") +
        " ( " +
        QuizInsights.items +
        " " +
        $t("Items") +
        " / " +
        QuizInsights.score +
        " " +
        $t("score") +
        " )"
      }}
    </p>
    <v-row class="ml-3">
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
        <label
          for="datadisplay"
          style="
            font-size: 16px;
            color: #424242;
            font-weight: bold;
            margin-top: 5px;
          "
          >{{ $t("datadisplay") }}:</label
        >
        <v-select
          v-model="datadisplay"
          :items="filtereddataitems"
          hide-details
          item-text="name"
          item-value="datadisplayvalue"
          hide-no-data
          dense
          outlined
          solo
          clearable
          multiple
          :placeholder="'--' + $t('pleaseselect') + '--'"
        >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  v-model="searchdata"
                  :placeholder="$t('search')"
                  autocomplete="off"
                  hide-details
                  class="searchinlist"
                  append-icon="mdi-magnify"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item ripple @click="getdataSelectAll">
              <v-list-item-action>
                <v-icon :color="datadisplay.length > 0 ? '#a6cc39' : ''">{{
                  icon
                }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"
              >(+{{ datadisplay.length - 1 }} others)</span
            >
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
        <label
          for="group"
          style="
            font-size: 16px;
            color: #424242;
            font-weight: bold;
            margin-top: 5px;
          "
          >{{ $t("group") }}:</label
        >
        <v-select
          v-model="dept"
          :items="groupOptions"
          hide-details
          item-text="group"
          item-value="group"
          hide-no-data
          dense
          outlined
          solo
          clearable
          multiple
          :placeholder="'--' + $t('pleaseselect') + '--'"
        >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  v-model="searchgroup"
                  :placeholder="$t('search')"
                  autocomplete="off"
                  hide-details
                  class="searchinlist"
                  append-icon="mdi-magnify"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item ripple @click="getgroupSelectAll">
              <v-list-item-action>
                <v-icon :color="dept.length > 0 ? '#a6cc39' : ''">{{
                  icon
                }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"
              >(+{{ dept.length - 1 }} others)</span
            >
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row class="ml-3">
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
        <label
          for="fullnamesm"
          style="
            font-size: 16px;
            color: #424242;
            font-weight: bold;
            margin-top: 5px;
          "
          >{{ $t("fullnamesm") }}:</label
        >
        <v-select
          v-model="QuizUserIdSelect"
          :items="FullNameItems"
          item-text="fullName"
          item-value="userId"
          hide-no-data
          :placeholder="'--' + $t('pleaseselect') + '--'"
          hide-details
          outlined
          solo
          dense
          clearable
          multiple
        >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  v-model="searchuser"
                  :placeholder="$t('search')"
                  @input="searchInLists(), (searchtype = 'user')"
                  autocomplete="off"
                  hide-details
                  append-icon="mdi-magnify"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item ripple @click="getSelectAll">
              <v-list-item-action>
                <v-icon :color="QuizUserIdSelect.length > 0 ? '#a6cc39' : ''">{{
                  icon
                }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.fullName }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"
              >(+{{ QuizUserIdSelect.length - 1 }} others)</span
            >
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pre-fix">
        <label
          for="quiztype"
          style="
            font-size: 16px;
            color: #424242;
            font-weight: bold;
            margin-top: 5px;
          "
          >{{ $t("quiztype") }}</label
        >
        <v-select
          v-model="quizSelectedList"
          :items="quizTypeItems"
          item-text="quiz_type"
          item-value="typeId"
          hide-no-data
          :placeholder="'--' + $t('pleaseselect') + '--'"
          hide-details
          outlined
          solo
          dense
          clearable
          multiple
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="selectAllQuizType">
              <v-list-item-action>
                <v-icon
                  v-if="quizSelectedList.length == quizTypeItems.length"
                  color="#a6cc39"
                >
                  mdi-checkbox-marked</v-icon
                >
                <v-icon
                  v-else
                  :color="quizSelectedList.length > 0 ? '#a6cc39' : ''"
                  >{{ icon }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.quiz_type }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"
              >(+{{ quizSelectedList.length - 1 }} others)</span
            >
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
        class="d-flex justify-space-between align-end"
      >
        <v-btn
          text
          class="text-capitalize"
          min-width="135"
          min-height="40"
          style="
            border: 1px solid #c0c0c0;
            border-radius: 8px;
            color: #424242;
            font-size: 16px;
          "
          >{{ $t("clearall") }}</v-btn
        >
        <v-btn
          min-width="135"
          min-height="40"
          depressed
          class="text-capitalize btnStyle btn_hover_effect ml-4"
          color="#A6CC39"
          >{{ $t("search") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="ml-5">
      <v-col cols="12" md="6" xl="6" sm="12" class="d-flex align-end">
        <span style="color: red; font-size: 18px">
          {{ $t("passingscore") + " : " }}</span
        >
        <span
          v-if="setting.passingScore !== undefined"
          style="color: red; font-size: 18px"
        >
          {{ setting.passingScore + " " + $t("score") }}</span
        >
      </v-col>
      <v-col
        cols="12"
        md="6"
        xl="6"
        sm="12"
        class="d-flex justify-end align-end"
      >
        <v-btn
          disabled
          outlined
          depressed
          color="#A6CC39"
          style="
            font-size: 16px;
            border: 1px solid #c0c0c0;
            border-radius: 8px;
            height: 40px;
          "
          class="text-capitalize rounded-lg mr-3"
        >
          <v-icon>mdi-file-upload-outline</v-icon>
          {{ $t("exportexcel") }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="my-3 ml-5 mt-5">
      <v-data-table
        :loading="quizuserloading"
        :loading-text="$t('loadingpleasewait')"
        :headers="responseTabUserHeader"
        :items="responseUserItems"
        hide-default-footer
        class="editquiz-table"
        @page-count="pageCount = $event"
        :page.sync="page"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-xs-start">{{ item.no }}</td>
            <td class="text-xs-center">
              <p>{{ item.fullName }}</p>
            </td>
            <td class="text-xs-start">
              <p>{{ item.group }}</p>
            </td>
            <td class="text-xs-start">
              <p>{{ item.courseName }}</p>
            </td>

            <td class="text-xs-start">
              <p>{{ item.corrected }}</p>
            </td>
            <td class="text-xs-center">
              <p style="width: 90px">{{ item.wrong }}</p>
            </td>
            <td class="text-xs-start">
              <p style="width: 100px">{{ item.totalScore }}</p>
            </td>
            <td class="text-xs-start">
              <p style="width: 130px">
                {{ $t(item.quizType.toLowerCase().replace(/[-\/\s]/g, "")) }}
              </p>
            </td>
            <td class="text-xs-start">
              <v-sheet
                v-if="setting.passingScore != 0"
                height="20px"
                width="65px"
                line-height="30px"
                :class="
                  item.passCriteriaQuiz === 'pass'
                    ? 'status_list'
                    : 'status_list1'
                "
              >
                {{ $t(item.passCriteriaQuiz) }}
              </v-sheet>
              <div v-else style="width: 85px; padding-left: 12px">
                {{ "-" }}
              </div>
            </td>
            <td class="text-xs-start">
              <p style="width: 100px">{{ item.finished }}</p>
            </td>
            <td class="text-xs-start">
              <v-sheet
                height="20px"
                width="65px"
                line-height="30px"
                :class="
                  item.status === 'active' ? 'status_list' : 'status_list1'
                "
              >
                {{ $t(item.status) }}
              </v-sheet>
            </td>
            <td>
              <div class="d-flex" style="margin-top: -5px">
                <v-btn
                  color="#4FB14E"
                  text
                  style="font-size: 16px"
                  class="text-capitalize"
                  @click="viewDetail(item)"
                >
                  <v-icon class="mr-2">mdi-eye-outline</v-icon>
                  {{ $t("view") }}
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <v-row justify="space-between" class="mx-3 my-4">
      <v-row>
        <v-col cols="auto" class="mr-auto">
          <v-pagination
            circle
            v-model="page"
            class="pagination"
            :length="pageCount"
          ></v-pagination>
        </v-col>

        <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
          <p class="mt-3 pr-4">{{ $t("Show") }}</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                width="70"
                v-bind="attrs"
                v-on="on"
                style="border: 1px solid #c0c0c0; opacity: 1"
                class="text-capitalize mt-1"
                outlined
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in paginationCount"
                :key="index"
                @click="itemsPerPage = item.title"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-row>
  </v-sheet>
</template>

<script>
import axios from "axios";

export default {
  components: {},

  data: () => ({
    quizId: null,
    quizuserloading: false,
    itemsPerPage: 10,
    pageCount: 0,
    page: 1,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    responseUserItems: [],
    setting: {},
    searchuser: null,
    quizSelectedList: [0, 1],
    FullNameItems: [],
    QuizUserIdSelect: [],
    groupdata: [],
    searchgroup: null,
    dataitems: [
      { name: "Current information", datadisplayvalue: 1 },
      { name: "Old information", datadisplayvalue: 2 },
    ],
    searchdata: "",
    dept: [],
    datadisplay: [1],
    QuizInsights: {
      items: "",
      score: "",
    },
    loading: false,
    courseItems: [],
  }),

  computed: {
    responseTabUserHeader() {
      return [
        {
          text: "#", //this.$t("no"),
          value: "no",
          class: "tableColor",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("fullnamesm"),
          align: "left",
          value: "fullName",
          class: "tableColor",
          width: "135",
          sortable: true,
        },
        {
          text: this.$t("group"),
          align: "left",
          value: "group",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("course"),
          align: "left",
          value: "courseName",
          class: "tableColor",
          width: "120",
          sortable: true,
        },
        {
          text: this.$t("corrected"),
          align: "left",
          value: "corrected",
          class: "tableColor",
          width: "120",
          sortable: true,
        },
        {
          text: this.$t("wrong"),
          align: "left",
          value: "wrong",
          class: "tableColor",
          width: "100",
          sortable: true,
        },
        {
          text: this.$t("score"),
          align: "left",
          value: "totalScore",
          class: "tableColor",
          width: "100",
          sortable: true,
        },
        {
          text: this.$t("quiztype"),
          align: "left",
          value: "quizType",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("passedthecriteria"),
          align: "left",
          value: "passCriteriaQuiz",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("finished"),
          align: "left",
          value: "finished",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("status"),
          align: "left",
          value: "status",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("Manage"),
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
    quizTypeItems() {
      return [
        { typeId: 0, quiz_type: this.$t("pretest") },
        { typeId: 1, quiz_type: this.$t("posttest") },
      ];
    },
    icon() {
      if (this.selectAll || this.selectGroupAll || this.selectdataAll)
        return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    groupOptions() {
      const query = (this.searchgroup || "").toLowerCase();
      const uniqueGroups = [
        ...new Set(
          this.groupdata.map((obj) => (obj.group !== null ? obj.group : "null"))
        ),
      ];
      return uniqueGroups.filter((group) =>
        group.toLowerCase().includes(query)
      );
    },
    filtereddataitems() {
      const searchTerm = this.searchdata.toLowerCase();
      return this.dataitems.filter((item) =>
        item.name.toLowerCase().includes(searchTerm)
      );
    },
    CourseTableHeaders() {
      return [
        {
          text: "#",
          value: "no",
          sortable: false,
        },
        {
          text: this.$t("assignmentcourse"),
          align: "left",
          value: "courseName",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("startdate"),
          align: "left",
          value: "startDate",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("enddate"),
          align: "left",
          value: "endDate",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("totaluser"),
          align: "left",
          value: "totalUser",
          class: "tableColor",
          sortable: false,
        },
        {
          text: this.$t("quizfinished"),
          align: "left",
          value: "quizFinised",
          class: "tableColor",
          sortable: true,
        },
        {
          text: this.$t("NotStarted"),
          align: "left",
          value: "notStarted",
          sortable: true,
          class: "tableColor",
        },
      ];
    },
    quiz_name() {
      return this.quizName;
    },
  },

  props: {
    quizName: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.quizId = localStorage.getItem("QuizId");
    this.GetQuizInsights();
    this.GetQuizSetting();
  },

  methods: {
    async GetQuizSetting() {
      let that = this;
      if(that.quizId === null) return;

      const userData = JSON.parse(
        localStorage.getItem("vivek_authenticated_user_data")
      );
      let member_Id = userData.memberId;      
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.quizId,
        createOrUpdateBy: member_Id,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizSetting`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.setting = response.data.data;
            if (that.setting.showQuizFinishWatching === true) {
              that.radioGroup = 1;
            } else if (that.setting.showQuizAfterWatching === true) {
              that.radioGroup = 2;
            } else if (that.setting.showQuizWhenever === true) {
              that.radioGroup = 3;
            }

            that.setting.passingScore =
              response.data.data.passingScore != null
                ? response.data.data.passingScore
                : 0;

            that.setting.showPassingScore =
              that.setting.passingScore == 0
                ? false
                : that.setting.showPassingScore;
          }
        })
        .catch(function (err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },

    async GetQuizInsights() {
      let that = this;
      if(that.quizId === null) return;
      let request = {
        companyID: localStorage.getItem("companyID"),
        quizId: that.quizId,
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizInsights`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.QuizInsights = response.data.data;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    selectAllQuizType() {
      if (this.quizSelectedList.length == 0) {
        this.quizSelectedList = this.quizTypeItems.map(({ typeId }) => typeId);
      } else {
        this.quizSelectedList = [];
      }
    },
    getSelectAll() {
      if (this.QuizUserIdSelect.length == 0) {
        this.QuizUserIdSelect = this.FullNameItems.map(({ userId }) => userId);
      } else {
        this.QuizUserIdSelect = [];
      }
    },
    getgroupSelectAll() {
      if (this.dept.length == 0) {
        this.dept = this.groupdata.map(({ group }) => group);
      } else {
        this.dept = [];
      }
    },
    getdataSelectAll() {
      if (this.datadisplay.length == 0) {
        this.datadisplay = this.dataitems;
      } else {
        this.datadisplay = [];
      }
    },
  },
};
</script>

<style scoped>
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}

::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 14px !important;
  color: #424242 !important;
  opacity: 1;
  text-align: center;
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
} /**.child_table */
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
</style>
